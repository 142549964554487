import { StaticImage } from "gatsby-plugin-image";
import React, { useContext, useRef, useState } from "react";
import styled from "styled-components";
import { DebrisTrackerContext } from "../../../context/DebrisTrackerContext";
import background from "../../../images/beach-clean-hero.jpg";
import Button from "../../Button/Button";
import { navigate } from "gatsby";
import registerUser from "../../../mdtApi/registerUser";

const SignUpWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow: hidden;
  justify-content: center;
`;

const SignUpInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 50px 40px;
  text-align: center;
  @media only screen and (max-width: 767px) {
    width: 75%;
  }
`;

const SignUpHeader = styled.div``;

const SignUpBackground = styled.div`
  display: flex;
  width: 50%;
  background-color: black;
  background-image: url(${background});
  background-size: cover;
  background-position: 100% 0;
  background-repeat: no-repeat;
  box-shadow: inset 0 300px 300px -100px rgba(0, 0, 0, 0.4);

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

const SignUpForm = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 100px;
  flex-grow: 1;

  @media only screen and (max-width: 767px) {
    margin-top: 40px;
    display: block;
  }
`;

const SignUpInputs = styled.form`
  display: flex;
  flex-direction: column;
`;

const NameInputContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;

  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const LoginSignup = styled.div`
  @media only screen and (max-width: 767px) {
    margin-top: 15px;
  }
`;

const SignUpInput = styled.input`
  border: 1px solid #d4d4d4;
  font-family: "Lato";
  font-size: 12pt;
  padding: 20px;
  margin: 10px 0px;
  width: auto;

  @media only screen and (max-width: 478px) {
    padding: 15px;
  }
`;

const ErrorContainer = styled.div`
  padding: 20px;
  border: solid 2px #801801;
  color: #801801;
  background-color: #dfdfdf;
`;

const SignUp = () => {
  // controlled input fields
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password1, setPassword1] = useState<string>("");
  const [password2, setPassword2] = useState<string>("");

  const [err, setErr] = useState<string>("");

  const formRef = useRef<any>(undefined);
  const { setUser } = useContext(DebrisTrackerContext);

  const testEmail = (email: string) => {
    const validEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return validEmail.test(email);
  };

  const validateSignup = () => {
    // check all fields
    if (
      !firstname ||
      !lastname ||
      !username ||
      !email ||
      !password1 ||
      !password2
    ) {
      setErr("All fields are required");
      return false;
    }

    // check passwords match
    if (password1 !== password2) {
      setErr("Passwords do not match");
      return false;
    }

    // check email is an email
    if (!testEmail(email)) {
      setErr("Email not valid");
      return false;
    }

    return true;
  };

  const handleSignUp = async (e?: any) => {
    if (e) {
      e.preventDefault();
    }
    if (!validateSignup()) {
      return;
    }
    try {
      const user = await registerUser(
        firstname,
        lastname,
        username,
        email,
        password1,
        password2
      );
      if (user.error) {
        setErr(user.error);
        return;
      } else if (!user) {
        throw "Failed";
      }
      setUser(user);
      sessionStorage.setItem("user", JSON.stringify(user));
      navigate("/");
    } catch (e) {
      setErr("Something went wrong - Please try again");
      console.error(e);
    }
  };

  return (
    <SignUpWrapper>
      <SignUpInputContainer>
        <SignUpHeader>
          <a href="/">
            <StaticImage
              src="../../../images/MarineDebrisTracker_Logo_Horizontal2.svg"
              quality={100}
              width={350}
              alt="Marine Debris Tracker"
              placeholder="tracedSVG"
            />
          </a>
        </SignUpHeader>
        <SignUpForm>
          <SignUpInputs ref={formRef} onSubmit={handleSignUp}>
            {err && <ErrorContainer>{err}</ErrorContainer>}
            <input type="submit" style={{ display: "none" }} />
            <NameInputContainer>
              <SignUpInput
                value={firstname}
                onChange={(e) => {
                  setFirstname(e.target.value);
                }}
                placeholder={"First Name"}
              />
              <SignUpInput
                value={lastname}
                onChange={(e) => {
                  setLastname(e.target.value);
                }}
                placeholder={"Last Name"}
              />
            </NameInputContainer>
            <SignUpInput
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              placeholder={"Username"}
            />
            <SignUpInput
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder={"Email"}
            />
            <SignUpInput
              value={password1}
              onChange={(e) => {
                setPassword1(e.target.value);
              }}
              placeholder={"Password"}
              type="password"
            />
            <SignUpInput
              value={password2}
              onChange={(e) => {
                setPassword2(e.target.value);
              }}
              placeholder={"Confirm Password"}
              type="password"
            />
            <Button btn={true} style={{ width: "100%", padding: "20px 0px" }}>
              Sign Up
            </Button>
          </SignUpInputs>
        </SignUpForm>
        <LoginSignup>
          Already have an account?{" "}
          <a style={{ textDecoration: "none" }} href="/login">
            Login!
          </a>
        </LoginSignup>
      </SignUpInputContainer>
      <SignUpBackground />
    </SignUpWrapper>
  );
};

export default SignUp;
