import fetch from "isomorphic-fetch";
import { devAPI } from "../util/urls";

const registerUser = async (
  firstname: string,
  lastname: string,
  username: string,
  email: string,
  password1: string,
  password2: string
) => {
  try {
    const res = await fetch(`${devAPI}/users/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        firstname,
        lastname,
        username,
        email,
        password1,
        password2,
      }),
    });
    if (res.status === 400) throw "This email is already in use";
    let parsed = await res.json();
    return parsed;
  } catch (err) {
    return { error: err };
  }
};

export default registerUser;
